:root, main.management{
    /* Navbar */
    --navbar-height: 80px;
    --navbar-bg-color: white;
    --navbar-shadow-color: rgba(0,0,0,.04);
    
    /* Sidebar */
    --sidebar-width: 100px;
    --sidebar-item-height: 100px;
    --sidebar-submenu-width: 300px;
    --sidebar-bg-color: white;
    --sidebar-borders-color: #c7c7c766;
    --sidebar-shadow-color: #0000001f;
    --nav-item-color: #3d3d3db3;
    --nav-item-color-hover: #3d3d3d;
    --acitve-nav-item-color: #ff9400;
    --nav-item-icon: #3d3d3db3;
    --filter-icons: grayscale(01);
    
    /* Body */
    --font-gray-color: #8f8f8f;
    --principal-bg-color: #fcfcff;
    --font-color: black;
    --input-border-color: #EBEBF2;
    --input-bg-color: white;
    
    /* Cards */
    --card-bg-color: white;
    --card-border-color: rgba(0,0,0,.125);
    --card-font-color: black;
    --shadow-color: rgba(132, 128, 177, 0.28);

    /* Scrollbar */
    --track-color: rgba(121, 121, 121, 0.11);
    --thumb-color: rgba(150, 150, 150, 0.5);
    
    /* Progress */
    --progress-circle-color: #ff9400; 
}

/* ======================= */
/* SIDEBAR STYLE
/* ======================= */
body[data-sidebar="sidebar1"] {
    --sidebar-width: 200px;
    --sidebar-item-height: 50px;
}

@media screen and (max-width: 500px){
    :root{
        --sidebar-submenu-width: calc(100vw - var(--sidebar-width)) 
    }

    body[data-sidebar="sidebar1"] {
        --sidebar-width: 240px;
    }
}
@media screen and (max-width: 767px){
    :root{
        --navbar-height: 70px;
        --sidebar-width: 90px;
    }
    body[data-sidebar="sidebar1"] {
        --sidebar-width: 250px;
    }
}
@media screen and (min-width: 1339px){
    :root{
        --sidebar-width: 110px;
        --navbar-height: 80px;
    }
    body[data-sidebar="sidebar1"] {
        --sidebar-width: 220px;
        --sidebar-item-height: 50px;
    }
}

/* ===================== */
/* THEME
/* ===================== */

body[data-theme="dark-theme"]{
    /* Navbar */
    --navbar-shadow-color: #151515c7;

    /* Sidebar */
    --sidebar-bg-color: #232323;
    --sidebar-shadow-color: #151515c7;
    /* --sidebar-shadow-color: #00000081; */
    --nav-item-color: #ffffffcc;
    --nav-item-color-hover: #ffffff;
    --nav-item-icon: #fff;
    
    /* Body */
    --principal-bg-color: #232323;
    --navbar-bg-color: #232323;
    --font-color: rgb(235, 235, 235);
    --font-muted-color: #fff;
    --input-bg-color: #333;
    --input-border-color: #4a4a4a;

    /* Cards */
    --card-bg-color: #333333;
    --card-border-color: #505050;
    --card-font-color: rgb(235, 235, 235);
    --shadow-color: #0000006b;

   /* Scrollbar */
   --track-color: #232323ef;
   --thumb-color: #505050;

    /* Progress */
    --progress-circle-color: #ff9400; 
}

body[data-theme="pink-theme"] {
    /* Navbar */
    --navbar-shadow-color: #c7009542;

    /* Sidebar */
    --sidebar-bg-color: #ffb2df;
    --sidebar-shadow-color: #c7009542;
    /* --sidebar-shadow-color: #00000081; */
    --nav-item-color: #000;
    --nav-item-color-hover: #fff;
    --nav-item-icon: #000;
    --acitve-nav-item-color: #ff008c;

    
    /* Body */
    --principal-bg-color: #ffebf7;
    --navbar-bg-color: #ffb2df;
    --font-color: rgb(54, 54, 54);
    --input-bg-color: #fff;
    --input-border-color: #ffcaf6;

    /* Cards */
    --card-bg-color: #ffcaf6;
    --card-border-color: #ffaced;

   /* Scrollbar */
   --track-color: #ffcae7ef;
   --thumb-color: #ff86c8;

    /* Progress */
    --progress-circle-color: #fff; 
}

body[data-theme="orange-theme"] {

    /* Sidebar */
    --sidebar-bg-color: #ff9400;
    --sidebar-borders-color: #ffffff66;
    --nav-item-color: #000;
    /* --nav-item-color: rgba(255, 255, 255, 0.877); */
    --filter-icons: grayscale(01) brightness(0.5);
    --nav-item-color-hover: #fff;
    --nav-item-icon: #fff;
    --acitve-nav-item-color: #ffffff;

    
    /* Body */
    --navbar-bg-color: linear-gradient(to right, #ff9400 60%, #ff7f00);
    /* --navbar-bg-color: #ff9400; */
    --font-color: rgb(54, 54, 54);
    --font-gray-color: #464646;

}
body[data-theme="orange-light-theme"] {
    
    /* Body */
    --navbar-bg-color: linear-gradient(to right, #ff9400 60%, #ff7f00);
    --font-color: rgb(54, 54, 54);
    --font-gray-color: #464646;
}

body[data-theme="green-theme"] {
 /* Sidebar */
 --sidebar-bg-color: #31ad68;
 --sidebar-borders-color: #ffffff66;
 --nav-item-color: #fff;
 /* --nav-item-color: rgba(255, 255, 255, 0.877); */
 --filter-icons: grayscale(01) brightness(0.5);
 --nav-item-color-hover: #000;
 --nav-item-icon: #000;
 --acitve-nav-item-color: #ffffff;
 
 /* Body */
 --navbar-bg-color: linear-gradient(to right, #31ad68 60%, #23af61);
 --font-color: rgb(54, 54, 54);
 --font-gray-color: #464646;
    --sidebar-bg-color: #31ad68;
}
body[data-theme="green-light-theme"] {
    /* Body */
    --navbar-bg-color: linear-gradient(to right, #31ad68 60%, #23af61);
    --font-color: rgb(54, 54, 54);
    --font-gray-color: #464646;

    /* Sidebar */
    --acitve-nav-item-color: #31ad68;

}

body,
body *:not(.NG-carousel > *) {
    transition: ease-in-out 100ms !important;
    transition-delay: 0 !important;
}
