@import 'vars.css';

body[data-theme="dark-theme"] .btn-light{
  background-color: #909090fa !important;
    border-color: #909090fa !important;
}

main.main-content {
  margin-top: calc(var(--navbar-height) + 25px);
  margin-bottom: 40px;
  margin-left: calc(var(--sidebar-width) + 25px);
  margin-right: 25px;
  transition: margin-left .3s;
}

.NG_sidebar.hide + main.main-content{
    margin-left: 25px;
}

.h-0{
  height: 0px !important;
}

@media screen and (max-width: 767px){
  .NG_sidebar.hide + main.main-content{
      margin-left: 0px;
  }
  main.main-content{
    margin-left: 0px;
    margin-right: 0px;
  }
}

/* ===================== */
/* GENERAL
/* ===================== */

#root {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

#root > main {
  flex: auto;
}

#root > footer {
  flex-shrink: 0 !important;
}

body {
  margin: 0;
  font-family: sans-serif, Arial, 'Quicksand';
  /* font-family: 'Quicksand', sans-serif; */
  color: var(--font-color) !important;
}

input::placeholder{
  color: #b3b3b3 !important;
}

input{
  color: var(--font-color) !important;
  background-color: var(--input-bg-color) !important;
  border-color: var(--input-border-color) !important;
}

.card,
.modal-content,
 .dropdown-menu  {
  background-color: var(--card-bg-color) !important;
  border-color: var(--card-border-color) !important;
  color: var(--card-font-color) !important;
}

.cursor-pointer{
  cursor: pointer !important;
}

.radius-10{
  border-radius: 10px !important;
}

.custom-switch.lg label{
  padding-top: 3px;
  padding-left: 10px;
}
.custom-switch.warning .custom-control-input:checked~.custom-control-label::before{
  border-color: #ff9400 !important;
  background-color: #ff9400 !important;
}
.custom-switch.lg label::before{
  height: 20px !important;
  width: 40px !important;
  border-radius: 20px !important;
}
.custom-switch.lg label::after{
  width: calc(20px - 4px) !important;
  height: calc(20px - 4px) !important;
}
.custom-switch.lg .custom-control-input:checked~.custom-control-label::after {
  background-color: #fff;
  transform: translateX(20px);
}

.opacity-05{
  opacity: 0.5 !important;
}

.bg-inherit{
  background: inherit !important;
}

.card-zoom:hover{
  box-shadow: 0 .5rem 1.15rem rgba(0,0,0,.1) !important;
  transform: scale(1.035) translate(0px, -5px);
  -webkit-transform: scale(1.035) translate(0px, -5px);
  -moz-transform: scale(1.035) translate(0px, -5px);
  -o-transform: scale(1.035) translate(0px, -5px);
}

.shadow-light-sm{
  box-shadow: 0 5px 20px -5px var(--shadow-color);
}
.shadow-light{
box-shadow: 0 9px 25px 0 rgba(132, 128, 177, 0.28);
}
.shadow-light-dark{
  box-shadow: 0 20px 30px -19px #4242423d;

  /* box-shadow: 0 9px 25px 0 #00000021; */
}

.shadow-btn-light{
 box-shadow: 0 12px 12px -11px rgba(211, 209, 210, 0.73);
}

input{
  border:1px solid var(--input-border-color) !important;
}

.btn-warning{
  color: white !important;
}

div.input-content-center input{
text-align: center !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.opacity-05-infinity{
  animation-name: opacity-05;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.iti { width: 100%; }
/* .iti__arrow { border: none; } */
.iti__country-list{
  background-color: var(--card-bg-color);
}

.ng-code-input{
  width: 100% !important;
}

.ng-main-content *{
  transition: all 0.3s ease-in-out;
}
.ng-main-content>div{
  z-index: 1;
}

.ng-code-input div input, .ng-code-input div input:first-child, .ng-code-input div input:last-child{
  font-family: 'Quicksand', sans-serif;
  border-radius: 100%;
  height: 50px !important;
  width: 50px !important;
  margin: 0px 4px 4px 4px;
}

.bg-light-gray{
  background-color: var(--principal-bg-color);
}

@media screen and (max-width: 575px){
  .bg-white-sm{
    background-color: white !important;
  }
.no-shadow-sm{
  box-shadow: none !important;   
}
.p-sm-0{
  padding: 0 !important;
}
.p-rl-sm-0{
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.m-sm-0{
  margin: 0 !important;
}
.m-rl-sm-0{
  margin-left: 0 !important;
  margin-right: 0 !important;
}
}

iframe, iframe + div{
  height: 100%;
  border: none;
  border-radius: 10px;
}
iframe{
  box-shadow: 0 20px 30px -19px #4242423d;
  background-color: white;
}

main.external-content{
  height: calc(100vh - (var(--navbar-height) + 30px + 30px));
  margin-bottom: 30px !important;
}

.custom-control-input.bg-warning:checked~.custom-control-label::before{
  border-color: #ff9400;
  background-color: #ff9400;
}

.text-gray{
  color: var(--font-gray-color) !important;
}

.text-muted{
  color: var(--font-muted-color) !important;
}

/************************************* 
* Scrollbar
**************************************/
@media screen and (min-width: 500px){
/* For the "inset" look only */
html {
  overflow: auto;
}
body {
overflow-x: hidden;
}

.scrollbar-hover-show{
  overflow: hidden !important;
}

.scrollbar-hover-show:hover {
  overflow: auto !important;
}

.scrollbar-transparent::-webkit-scrollbar-track{
  background-color: transparent !important;
}

.scrollbar-sm::-webkit-scrollbar{
  width: 3px !important;
}

/* Let's get this party started */
::-webkit-scrollbar {
  width: 6px;
  transition: all 0.2s ease-in-out;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: var(--track-color);
  -webkit-border-radius: 10px;
  border-radius: 3px;
  transition: all 0.2s ease-in-out;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 3px;
  background: var(--thumb-color); 
}
/* ::-webkit-scrollbar-thumb:window-inactive {
  background:transparent; 
}
::-webkit-scrollbar-track:window-inactive {
  background:transparent; 
} */
::-webkit-scrollbar-track:hover{
  background-color: #0000001a;
} 
}
  
  @keyframes onAutoFillStart { from {} to {}}
/* @keyframes onAutoFillCancel { from {} to {}} */

input:-webkit-autofill {
    transition: background-color 5s linear 0s;
    animation-name: onAutoFillStart;
}

/* input:not(:-webkit-autofill) {
    animation-name: onAutoFillCancel;
} */

@keyframes opacity-05{
  0%{
    opacity: 0.5;
  }
  100%{
    opacity: 1;
  }
}

input.with-eye {
  padding-right: calc(16px + 1.50rem) !important;
}
input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

.NG-floating-border-div > div{
  display: flex;
  align-items: center;
  height: -webkit-fill-available !important;
}